.product_item {
  @apply w-64 h-auto bg-white border-4 border-gray-light flex flex-col items-start justify-between rounded-card transition-all;

  .pi-image {
    @apply relative w-full bg-white mt-2 flex flex-col items-center justify-center rounded-card;

    img {
      @apply relative z-5;
    }
  }

  .pi-img-badge {
    @apply absolute z-10 w-14 h-14 p-1 flex items-center overflow-hidden justify-center uppercase rounded-full;

    &.pi-new {
      @apply top-2 -mt-1 left-3 bg-orange-400 font-semibold text-3xl text-white -rotate-6;

      &--nex {
        @apply top-2 left-auto right-1;
      }
    }

    &.pi-promo {
      @apply w-18 h-10 bottom-2 left-3 bg-yellow-400/80 font-semibold text-2xl text-black -rotate-6;

      &--nex {
        @apply w-full h-10 bottom-0 left-1 rounded-b-lg rounded-t-md left-0 bg-yellow-400/80 font-semibold text-2xl text-black rotate-0;
      }
    }

    &.pi-action {
      @apply bottom-2 right-3 w-20 h-20  bg-red/70 font-semibold text-3xl  -rotate-45;

      svg.circle-text {
        @apply text-white origin-center h-auto fill-current w-full max-w-[66vmin];
      }

      svg.action-icon {
        @apply text-yellow-400 rotate-45 w-[28%] absolute;
      }

      &--nex {
        @apply bottom-12 right-1;
      }
    }

    &.pi-discount {
      @apply top-2 right-3 font-semibold text-white text-2xl -rotate-6;

      &--nex {
        @apply top-12 right-1;
      }
    }

    &.pi-brand-badge {
      @apply bottom-2 right-3 bg-transparent text-white rounded-none;

      img {
        @apply max-w-14 max-h-14;
      }
    }
  }

  .pi-title {
    @apply w-full h-auto px-4 pt-2 flex flex-col items-start justify-start;
  }

  &:active {
    @apply border-gray-light drop-shadow-xl scale-95;
  }

  &--middle {
    @apply w-72 border-2;

    .pi-image {
      @apply h-56;
    }
  }

  &--fullhd {
    @apply w-[16.5rem];
  }

  &--active {
    @apply border-green;
  }

  &--nex-flat {
    @apply relative w-[174px] h-[296px] rounded-xl overflow-hidden border-4 border-nex-gray-light bg-white;

    img {
      @apply object-cover relative z-5;
    }
  }
  &--nex-normal {
    @apply relative w-[234px] h-[399px] rounded-xl overflow-hidden border-4 border-nex-gray-light bg-white;

    img {
      @apply object-cover relative z-5;
    }

    .pi-image {
      @apply relative w-full bg-white flex flex-col items-center justify-center rounded-t-card p-0 m-0;

      img {
        @apply relative z-5;
      }
    }
  }
}

.cart_item {
  @apply w-full h-32 max-h-32 min-h-32  bg-white border-5 border-nex-gray-light flex flex-row items-center justify-between rounded-xl;

  .ci_image {
    @apply w-28 h-28 flex flex-col items-center justify-center;
  }

  .ci_title {
    @apply w-auto flex-1 h-full px-4  flex flex-col items-start justify-center;
  }
  .ci_quantity_actions {
    @apply w-1/5 h-full items-center flex-row justify-between pr-4 space-x-2 flex;
  }

  .ci_price {
    @apply w-40 h-full pr-6 items-center justify-center bg-nex-gray-light flex font-medium;
  }

  &--wscroll {
    @apply w-[97%];
  }
}

.pi-details-img-badge {
  @apply absolute z-10 w-14 h-14 p-1 flex items-center overflow-hidden justify-center uppercase rounded-full;

  &.pi-details-new {
    @apply top-3 -mt-1 left-3 bg-orange-400 font-semibold text-3xl text-white -rotate-6;
  }

  &.pi-details-promo {
    @apply w-24 top-3 -mt-1 left-20 bg-yellow-400 font-semibold text-3xl text-black -rotate-6;
  }

  &.pi-details-action {
    @apply bottom-2 right-3 w-20 h-20  bg-red/70 font-semibold text-3xl  -rotate-45;

    svg.circle-text {
      @apply text-white origin-center h-auto fill-current w-full max-w-[66vmin];
    }

    svg.action-icon {
      @apply text-yellow-400 rotate-45 w-[28%] absolute;
    }
  }

  &.pi-details-discount {
    @apply bottom-2 left-3 font-semibold text-white text-2xl -rotate-6;
  }

  &.pi-details-brand-badge {
    @apply bottom-2 right-3 bg-transparent text-white rounded-none;

    img {
      @apply max-w-14 max-h-14;
    }
  }
}
