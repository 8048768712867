#countdown {
  svg {
    height: $progress-bar-size;
    transform: rotate(-90deg);
    width: $progress-bar-size;
  }

  .progress-bar__background {
    fill: none;
    stroke: #e2eff0;
    stroke-width: $progress-bar-stroke-width;
  }
  .progress-bar__background_transparent {
    fill: none;
    stroke: transparent;
    stroke-width: $progress-bar-stroke-width;
  }

  .progress-bar__progress {
    fill: none;
    stroke: #e2eff0;
    stroke: currentColor;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: $progress-bar-stroke-width;
  }
}

#countdown-nex {
  svg {
    height: $progress-bar-size;
    transform: rotate(-90deg);
    width: $progress-bar-size;
  }

  .progress-bar__background {
    fill: none;
    stroke: #ffffff;
    stroke-width: $progress-bar-stroke-width;
  }
  .progress-bar__background_transparent {
    fill: none;
    stroke: transparent;
    stroke-width: $progress-bar-stroke-width;
  }

  .progress-bar__progress {
    fill: none;
    stroke: #ffffff;
    stroke: currentColor;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: $progress-bar-stroke-width;
  }
}

#countdown-sanusx {
  svg {
    height: 211.83px;
    transform: rotate(-90deg);
    width: 211.83px;
  }

  .progress-bar__background {
    fill: none;
    stroke: #e2eff0;
    stroke-width: $sanusx-progress-bar-stroke-width;
  }

  .progress-bar__progress {
    fill: none;
    stroke: #ffffff;
    stroke: currentColor;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: square;
    stroke-width: $sanusx-progress-bar-stroke-width;
  }
}
#loader-sanusx {
  svg {
    height: $sanusx-progress-bar-size;
    transform: rotate(-90deg);
    width: $sanusx-progress-bar-size;
  }

  .progress-bar__background {
    fill: none;
    stroke: #e2eff0;
    stroke-width: $sanusx-progress-bar-stroke-width * 2;
  }

  .progress-bar__progress {
    fill: none;
    stroke: #e2eff06e;
    stroke: currentColor;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: square;
    // transition: stroke-dashoffset infinite ease-in-out;
    stroke-dashoffset: 130;
    stroke-width: $sanusx-progress-bar-stroke-width;
    animation: dash 5s linear infinite;
  }
}
#loader {
  svg {
    height: $progress-bar-size;
    transform: rotate(-90deg);
    width: $progress-bar-size;
  }

  .progress-bar__background {
    fill: none;
    stroke: #e2eff0;
    stroke-width: $progress-bar-stroke-width * 2;
  }

  .progress-bar__progress {
    fill: none;
    stroke: #e2eff0;
    stroke: currentColor;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    // transition: stroke-dashoffset infinite ease-in-out;
    stroke-dashoffset: 130;
    stroke-width: $progress-bar-stroke-width;
    animation: dash 5s linear infinite;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}
