#waver {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

#nhdwave {
  @apply w-full h-full bg-transparent overflow-hidden -z-1 absolute left-0 top-0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

#nhdwave .fill {
  animation-name: fillAction;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.2, 0.6, 0.8, 0.4);
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

#nhdwave #waveShape {
  @apply w-[300px] h-[150px];
  animation-name: waveAction;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.5s;
}

@keyframes fillAction {
  0% {
    transform: translate(0, 50%);
  }
  100% {
    transform: translate(0, -5px);
  }
}

@keyframes waveAction {
  0% {
    transform: translate(-150px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
