.btn-ski {
  @apply outline-none ring-0 transition-all relative ease-in-out flex items-center flex-row w-full h-15;
  -webkit-tap-highlight-color: transparent;

  .label {
    @apply flex-1 text-2xl font-normal leading-[0px] text-white tracking-wide uppercase;
  }

  .icon {
    @apply rounded-full border-5 bg-white flex flex-col items-center justify-center w-15 h-15;

    .letter-in {
      @apply transition-all rounded-full w-12 h-12 font-semibold flex-row flex items-center justify-center relative text-3xl text-center;

      .circle {
        @apply absolute transition-all flex flex-col items-center justify-center w-24 h-24 rounded-full -z-1 opacity-40;

        .circle-in {
          @apply absolute w-20 h-20 rounded-full;
        }
      }
    }
  }

  &:focus,
  &:active {
    @apply shadow-xl;

    .icon {
      @apply bg-white shadow-xl;

      .letter-in {
        .circle {
          @apply opacity-100 animate-ping-short;
        }
      }
    }
  }

  &--left {
    @apply pl-7 text-left rounded-r-full;

    .label {
      @apply text-left order-1 pr-6;
    }

    .icon {
      @apply order-2;
    }
  }

  &--right {
    @apply pr-7 text-right rounded-l-full;

    .label {
      @apply text-right order-2 pl-6;
    }
    .icon {
      @apply order-1;
    }
  }

  &--super {
    &.btn-ski--right {
      .label-super {
        @apply pl-6 order-2;
      }
      .icon {
        @apply justify-start;

        .letter-in {
          @apply justify-start;
        }

        .circle {
          @apply -left-6;
        }
      }
    }
    &.btn-ski--left {
      .label-super {
        @apply pr-6;
      }
    }
    .label-super {
      @apply text-[1.70rem] font-normal leading-[0px] tracking-wide px-2;
    }
    .icon {
      @apply rounded-full border-5 bg-white flex flex-col items-center justify-end w-full h-15;

      .letter-in {
        @apply px-8 transition-all rounded-full w-full h-full font-semibold flex-row flex items-center justify-end relative text-3xl text-center;

        .circle {
          @apply -right-6 absolute transition-all flex flex-col items-center justify-center w-[118%] h-24 rounded-full -z-1 opacity-40;

          .circle-in {
            @apply absolute w-[95%] h-20 rounded-full;
          }
        }
      }
    }
  }

  &--subaction {
    @apply bg-primary-dark;

    .icon {
      @apply border-primary-dark;

      .letter-in {
        @apply text-red flex flex-row items-center justify-center;
        text-indent: -200%;

        .icon-subaction {
          @apply flex flex-row items-center justify-center;

          app-svg-stripe {
            @apply w-8 h-8 text-primary-dark flex-row;

            svg {
              @apply text-primary-dark;
            }
          }
        }

        .circle {
          @apply bg-primary-dark/30;
          .circle-in {
            @apply bg-primary-dark/50;
          }
        }
      }
    }
  }

  &--subaction-finish {
    @apply bg-success;

    .icon {
      @apply border-success;

      .letter-in {
        @apply text-transparent flex flex-row items-center justify-center mx-auto;

        .icon-subaction {
          @apply flex flex-row items-center justify-center mx-auto;

          app-svg-stripe {
            @apply h-8 text-success flex mx-auto;

            svg {
              @apply text-success mx-auto;
            }
          }
        }

        .circle {
          @apply bg-success/30;
          .circle-in {
            @apply bg-success/50;
          }
        }
      }
    }
  }

  &--selected {
    .icon {
      .checkmark {
        @apply flex flex-col items-center justify-center w-7 h-7 rounded-full text-secondary p-1 absolute -bottom-5;
      }
    }
  }

  &--selectednumber {
    @apply justify-end bg-transparent;

    &.btn-ski--left {
      @apply pl-0;
    }
    &.btn-ski--right {
      @apply justify-start;
    }
    .label {
      @apply hidden;
    }
    .icon {
      .checkmark {
        @apply flex flex-col items-center justify-center w-7 h-7 rounded-full text-secondary p-1 absolute -bottom-5;
      }
    }

    &:focus,
    &:active {
      @apply shadow-none;

      .icon {
        @apply shadow-none;

        .letter-in {
          .circle {
            @apply opacity-100;
          }
        }
      }
    }

    &-notChecked {
      .icon {
        .checkmark {
          @apply invisible;
        }
      }
    }
  }

  &--error {
    @apply justify-end h-15 bg-transparent;

    &.btn-ski--right {
      @apply justify-start;
    }

    .label {
      @apply hidden;
    }
    .icon {
      @apply bg-red border-red;

      .letter-in {
        @apply text-white;

        .circle {
          @apply bg-red/30;

          .circle-in {
            @apply bg-red/50;
          }
        }
      }

      .errormark {
        @apply flex bg-red flex-col items-center justify-center w-7 h-7 rounded-full text-white p-1 absolute -bottom-5;
      }
    }

    &:focus,
    &:active {
      @apply shadow-none;

      .icon {
        @apply shadow-none  bg-red border-red;

        .letter-in {
          .circle {
            @apply opacity-100;
          }
        }
      }
    }
  }

  &--wait {
    @apply justify-end h-15 bg-transparent;

    &.btn-ski--right {
      @apply justify-start;
    }

    .label {
      @apply hidden;
    }
    .icon {
      @apply bg-orange-500 border-orange-500;

      .letter-in {
        text-indent: -200px;
        @apply text-white text-transparent;

        .btn-loader-mini {
          animation: rotate2 2s linear infinite;
          z-index: 2;
          position: relative;
          width: 35px;
          height: 35px;

          & .path {
            stroke: #ffffff;
            stroke-linecap: round;
            animation: dash2 1.5s ease-in-out infinite;
          }
        }

        .circle {
          @apply bg-orange-500/30;

          .circle-in {
            @apply bg-orange-500/50;
          }
        }
      }
    }

    &:focus,
    &:active {
      @apply shadow-none;

      .icon {
        @apply shadow-none  bg-orange-500 border-orange-500;

        .letter-in {
          .circle {
            @apply opacity-100;
          }
        }
      }
    }
  }

  &--big {
    .label {
      @apply flex-1 text-2xl font-semibold text-white tracking-wider;
    }
    .icon {
      @apply border-5 bg-white w-28 h-28;

      .circle {
        @apply absolute transition-all flex flex-col items-center justify-center w-53 h-53 rounded-full -z-1 opacity-40;

        .circle-in {
          @apply absolute w-40 h-40 rounded-full;
        }
      }

      .icon-in {
        @apply w-18 h-18 rounded-full flex flex-row items-center justify-center;

        app-svg-stripe {
          @apply w-8 h-8 text-white flex mx-auto;

          svg {
            @apply text-white;
          }
        }
      }
    }

    &:focus,
    &:active {
      @apply shadow-2xl;

      .icon {
        @apply bg-gray-snow shadow-2xl;

        .icon-in {
          @apply shadow-2xl;
        }
        .circle {
          @apply opacity-100 animate-ping-short;
        }
      }
    }
  }
}

@keyframes rotate2 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash2 {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

// &--big {

//   .label {}
//   .icon {

//     .icon-in {

//       &:after {}

//       &:before {}

//       app-svg-stripe {

//         svg
//       }
//     }
//   }
// }
