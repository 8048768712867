.product-info-content {
  @apply text-gray;
  h1 {
    @apply text-5xl font-bold;
  }
  h2 {
    @apply text-5xl font-semibold;
  }
  h3 {
    @apply text-4xl;
  }
  h4 {
    @apply text-3xl;
  }
  h5 {
    @apply text-2xl;
  }
  h6 {
    @apply text-xl;
  }
  p,
  div,
  a,
  li {
    @apply text-2xl;
  }
  p {
    margin-bottom: 1em;
  }
  a {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  ul {
    @apply list-disc list-outside pl-4;
    li {
    }
  }
  ol {
    @apply list-decimal list-outside pl-4;
    li {
    }
  }

  hr {
    @apply block w-full h-1 bg-gray my-3;
  }

  blockquote {
    @apply italic;
  }

  address {
    @apply font-semibold pl-4;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    @apply hidden;
  }

  @apply pr-2 overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-2.5;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-light rounded-full transition-all;
  }
}
.nex-product-info-content {
  @apply text-gray;
  h1 {
    @apply text-5xl font-bold py-2;
  }
  h2 {
    @apply text-5xl font-semibold py-2;
  }
  h3 {
    @apply text-4xl py-2;
  }
  h4 {
    @apply text-3xl py-2;
  }
  h5 {
    @apply text-2xl py-2;
  }
  h6 {
    @apply text-xl py-2;
  }
  p,
  div,
  a,
  li {
    @apply text-2xl;
  }
  a {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  ul {
    @apply list-disc list-inside ml-4 py-4;
    li {
      p {
        @apply inline;
      }
    }
  }
  ol {
    @apply list-decimal list-inside ml-4 py-4;
    li {
      p {
        @apply inline;
      }
    }
  }

  hr {
    @apply block w-full h-1 bg-gray my-3;
  }

  blockquote {
    @apply italic py-2 pl-4 font-semibold ml-2 border-l-2 border-nex-gray;
  }

  address {
    @apply font-semibold pl-4;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    @apply hidden;
  }

  @apply pr-2 overflow-y-scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    @apply w-8;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-nex-gray-light rounded-xl transition-all;
  }
}
