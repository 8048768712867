.locker-inner {
  @apply border-4 border-white-30 w-full h-full flex flex-col justify-center items-center text-gray text-2xl font-bold bg-gray-light;
  min-width: 5rem;
  min-height: 3rem;

  &--free {
    @apply bg-green text-3xl cursor-pointer text-white;

    &:focus {
      @apply outline-none drop-shadow-xl;
    }
    .locker-info {
      display: flex !important;
    }
  }

  &--activated {
    @apply text-3xl  text-white z-50 relative;
      &:focus {
        @apply outline-none drop-shadow-xl;
      }
        .locker-info {
          display: flex !important;
        }
  }

  &--selected {
    @apply bg-green text-3xl leading-tight text-white shadow-2xl z-50 relative;
  }

  &--disabled {
    @apply hover:shadow-none opacity-70 cursor-not-allowed;
  }

  .locker-info {
    @apply hidden text-2xl text-white-80 leading-tight font-normal tracking-wide  flex-row space-x-px;
    span {
      // temp hidden
      @apply hidden uppercase;
      // @apply uppercase;

      &:nth-child(1) {
        &:after {
          content: "x";
          position: relative;
          top: -0.1em;
          font-size: 60%;
          left: 0.1em;
        }
      }
      &:nth-child(2) {
        &:after {
          content: "x";
          position: relative;
          top: -0.1em;
          font-size: 60%;
          left: 0.1em;
        }
      }
      &:nth-child(3) {
        &:after {
          content: "cm";
          text-transform: lowercase;
          position: relative;
          bottom: 0;
          font-size: 60%;
          left: 0.1em;
        }
      }
    }
  }
}

.locker-display {
  @apply w-full h-full flex flex-col p-6 justify-center items-center text-transparent font-semibold bg-gray overflow-hidden;
  font-size: 0;
  text-indent: -9999px;
}

.screen {
  @apply border border-gray-light-50 w-full h-full bg-gradient-to-br from-gray via-gray-light-50 to-gray rounded-lg;
}


.locker-black::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1;
  /* Firefox */
}

.locker-black:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.locker-black::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}
