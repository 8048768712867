.side-area {
  @apply relative;

  &::after {
    content: "";
    @apply absolute z-20 block left-0 top-0 w-6 h-full bg-gradient-to-r from-nex-black to-transparent opacity-20;
  }
}

.vert-shadow {
  @apply relative;

  &::after {
    content: "";
    @apply absolute z-20 block left-0 top-12 w-full h-6 bg-gradient-to-b from-nex-black to-transparent opacity-5;
  }
}
