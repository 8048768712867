.category_item {
  @apply w-57 h-auto bg-white border-5 border-gray-ultra flex flex-col items-start justify-between rounded-card;

  .pi-image {
    @apply relative w-full h-40 flex flex-col items-center justify-center bg-white rounded-card border-b-2 border-gray-ultra;

    img {
      @apply relative z-5 object-cover w-full h-full;
    }
  }

  .pi-title {
    @apply w-full h-auto px-4 pt-2 flex flex-col items-center justify-center;
  }

  &:active {
    @apply border-gray-light drop-shadow-xl scale-95;
  }

  &--middle {
    @apply w-72 border-2;

    .pi-image {
      @apply h-56;
    }
  }

  &--fullhd {
    @apply w-[15.5rem];
  }
}

.cart_itemx {
  @apply w-full h-32 max-h-32 min-h-32  bg-white border-5 border-gray-light flex flex-row items-center justify-between rounded-card;

  .ci_image {
    @apply w-28 h-28 flex flex-col items-center justify-center;
  }

  .ci_title {
    @apply w-auto flex-1 h-full px-4  flex flex-col items-start justify-center;
  }
  .ci_quantity_actions {
    @apply w-1/3 h-full items-center flex-row justify-between pr-4 space-x-2 flex;
  }

  .ci_price {
    @apply w-32 h-full pr-6 items-center justify-center bg-gray-light-50 flex font-bold;
  }

  &--wscroll {
    @apply w-[97%];
  }
}
